body {
    margin: 0;
    font-family: "Nunito", sans-serif !important;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Loading Component */
/**===== dots5 =====*/
#dots5 {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
}

#dots5 span {
    position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    -webkit-animation: dots5 1s infinite ease-in-out;
    animation: dots5 1s infinite ease-in-out;
}

#dots5 span:nth-child(1) {
    left: 0px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

#dots5 span:nth-child(2) {
    left: 15px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

#dots5 span:nth-child(3) {
    left: 30px;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

#dots5 span:nth-child(4) {
    left: 45px;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

@keyframes dots5 {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        background: #d62d20;
    }
    25% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        background: #ffa700;
    }
    50% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        background: #008744;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        background: #0057e7;
    }
}
@-webkit-keyframes dots5 {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        background: #d62d20;
    }
    25% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        background: #ffa700;
    }
    50% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        background: #008744;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        background: #0057e7;
    }
}
/** END of loading component */

.dot {
    height: 30px;
    width: 30px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.active-user {
    background-color: #00e676 !important;
}

.status-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
}

.staff-color {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: inline-block;
}

.modal-90w {
    margin: auto;
    width: 1200px;
}

.btn-outline-secondary {
    border-color: transparent !important;
}

.category-item-container {
    background-color: rgb(240, 236, 235) !important;
}

.category-item-header-container {
    border-style: none !important;
    background-color: transparent !important;
}

.card-item-header {
    background-color: transparent !important;
}

.card-header {
    background-color: transparent !important;
}

/* .modal-lg{
  min-width: 100% !important;
  min-height: 100% !important;
  padding: 0px !important;
  margin: 0px !important
}
.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
} */

.checkboxService {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkboxService > span {
    color: #000000;
    padding: 0.5rem 0.25rem;
    padding-bottom: 5px;
    position: relative;
    margin-left: 5px;
    top: -5px;
}

.checkboxService > input {
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    border: 1px #41b883 solid;
    transition-duration: 0.3s;
    background-color: #fff;
    cursor: pointer;
}

.checkboxService > input:checked {
    border: 1px solid #fff;
    background-color: #41b883;
}

.checkboxService > input:checked + span::before {
    content: "\2713";
    display: block;
    height: 30px;
    width: 30px;
    text-align: center;
    color: #fff;
    position: absolute;
    left: -32px;
    top: 5px;
}

.checkboxService > input:active {
    border: 3px solid #fff;
}

.day-of-weeks {
    font-family: times, Times New Roman, times-roman, georgia, serif;
    color: #444;
    margin: 0;
    padding: 0px 0px 6px 0px;
    font-size: 25px;
    line-height: 44px;
    letter-spacing: -1px;
    font-weight: bold;
}

/* tags input for business types */
.business-type-tags-container {
    margin-top: 10px;

    display: inline-flex;
    text-align: center;
}

.business-type-tag {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #dcdcdc;
    background-color: #dcdcdc;
    border-radius: 5px;
}
.business-type-tag:hover {
    border: 1px solid #cacfd2;
    background-color: #cacfd2;
}

.close {
    margin-left: 7px;
}

/* end tags input for business types */

/* Avartar for staff */
.avatar {
    width: 52px;
    height: 52px;
    background-color: #ccc;
    border-radius: 50%;
    font-family: sans-serif;
    color: #fff;
    font-weight: bold;
    font-size: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* tags input for service taxes */
.service-tax-tags-container {
    margin-top: 10px;

    display: inline-flex;
    text-align: center;
}

.service-tax-tag {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #c2e3f7;
    background-color: #c2e3f7;
    border-radius: 5px;
}
.service-tax-tag:hover {
    border: 1px solid #cadee3;
    background-color: #cadee3;
}

.service-tax-tag-close {
    margin-left: 7px;
}

.error-message-container {
    position: fixed;
    top: 100px;
    right: 0;
    min-width: 300px;
    z-index: 1080;
}
